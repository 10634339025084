<template>
    <div>
        <div class="pdfok" id="pdfok" v-if="pdfok"></div>

        <div id="printMe" style=" width: 21cm; margin: 0 auto;">
            <Zijian_01 :stuinfo="projectInfo" :principal_info="principal_info"></Zijian_01>
            <Zijian_02 :stuinfo="projectInfo" :principal_info="principal_info" :memberList="memberList" :teacherList="teacherList"></Zijian_02>
            <Zijian_03 :stuinfo="projectInfo" :principal_info="principal_info" :memberList="memberList" :teacherList="teacherList"></Zijian_03>
            <Zijian_04 :stuinfo="projectInfo" :principal_info="principal_info" :memberList="memberList" :teacherList="teacherList"></Zijian_04>
          
        </div>


        <el-button v-if="false" v-print="'#printMe'" type="primary" style="position: fixed;top: 0;left: 0;z-index: 999;">打印
        </el-button>

    </div>
</template>

<script>
import Zijian_01 from './com/cover'
import Zijian_02 from './com/perInfo'
import Zijian_03 from './com/zijian_03'
import Zijian_04 from './com/zijian_04'

export default {
    name: 'exportZijian',
    components: {
        Zijian_01,
        Zijian_02,
        Zijian_03,
        Zijian_04
    },
    data() {
        return {
            pdfok: false,
            printObj: {
                id: 'printMe',
                popTitle: '',
                extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>'
            },
            userid: '',
            cover: {},

            stuInfo: {},
            yuebao: [{}],
            zhoubao: [{}],
            kemu: [],
            arrHealth: [{ dictValue: 1, dictLabel: '健康' }, { dictValue: 2, dictLabel: '良好' }, { dictValue: 3, dictLabel: '较差' }],
              projectInfo: {},
            teacherList: [{}],
            principal_info: {},
            memberList: [{}],
            files: [],
        }
    },
    mounted() {
        let stu_id = this.getParams('id')
        let id = this.getParams("id");
        // id = 148
        this.getData(id)
    },
    methods: {
        //过滤特殊字符, 校验所有输入域是否含有特殊符号
        stripscript(s) {
            var mdash = '&mdash;'
            var nbsp = '&nbsp;'
            var quot = '&quot;'
            var ldquo = '&ldquo;'
            var rdquo = '&rdquo;'
            var amp = '&amp;'
            var lt = '&lt;'
            var gt = '&gt;'


            var rs = ''
            rs = s.replace(new RegExp(mdash, 'g'), '-');
            rs = rs.replace(new RegExp(nbsp, 'g'), '');
            rs = rs.replace(new RegExp(quot, 'g'), '"');
            rs = rs.replace(new RegExp(ldquo, 'g'), '“');
            rs = rs.replace(new RegExp(rdquo, 'g'), '”');
            rs = rs.replace(new RegExp(amp, 'g'), '&');
            rs = rs.replace(new RegExp(lt, 'g'), '<');
            rs = rs.replace(new RegExp(gt, 'g'), '>');
            return rs
        },
        timeAdd0(n) {
            n = n.toString();
            return n[1] ? n : '0' + n
        },

        formatshijianchuo(shijianchuo) {
            //shijianchuo是整数，否则要parseInt转换
            var time = new Date(shijianchuo)
            var y = time.getFullYear()
            var m = time.getMonth() + 1
            var d = time.getDate()
            var h = time.getHours()
            var mm = time.getMinutes()
            var s = time.getSeconds()
            return y + '-' + this.timeAdd0(m) + '-' + this.timeAdd0(d)
        },
        getData(id) {
            let _this = this
            this.$http.get(`http://ckmall.aiwx.org.cn/api/project/show/${id}`).then(res => {
                let info = JSON.parse(JSON.stringify(res.data.data.project))
                this.projectInfo = info
                this.teacherList = info.teachers
                this.principal_info = info.principal_info
                this.memberList = info.members
                this.files = info.opinion ? JSON.parse(info.opinion) : []

                if (info.udate) {
                    info.udate = info.udate.substring(0,10)
                    
                }
                this.stuInfo = info
                this.$nextTick(() => {
                    setTimeout(() => { _this.pdfok = true }, 500)
                })

            })
        },
        getKemu(stu_id, pmid) {
            this.$http.post('/api/stu_examscore_list_public', {
                stu_id: stu_id, pmid: pmid
            }).then(res => {
                if (res && res.data && res.data.length > 0) {
                    this.kemu = this.handleGroup(res.data)
                } else {
                    this.kemu = this.handleGroup([])
                }
            }).catch(_ => {
                this.kemu = this.handleGroup([])
            })
        },
        handleGroup(kemu) {
            if (!kemu || kemu.length == 0) {
                kemu = new Array(28).fill({})
            } else if (kemu.length < 28) {
                let difflen = 28 - (kemu.length)
                kemu = this._lo.concat(kemu, new Array(difflen).fill({}))

            }
            let kemuGroup = this._lo.chunk(kemu, 7)
            let kemuResult = kemuGroup.splice(0, 4)
            return kemuResult
        },

        getParams(name) {
            let n_url = window.location.href
            n_url = n_url.replace('/#', '')
            if (n_url.indexOf('?') > 0) {
                n_url = n_url.split('?')[1]
            }
            var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
            var r = n_url.match(reg)
            if (r != null) return unescape(r[2])
            return null
        },
        //分割数组 arr：原数组  num：分割后每个数组里的数据条数
        util_spliceArrByN(arr, num) {
            var _arr = []
            while (arr.length) {
                _arr.push(arr.splice(0, num))
            }
            return _arr
        },
        //数组按某属性分组
        groupBy(array, f) {
            let groups = {}
            array.forEach(function (o) {
                let group = JSON.stringify(f(o))
                groups[group] = groups[group] || []
                groups[group].push(o)
            })
            return Object.keys(groups).map(function (group) {
                return groups[group]
            })
        }
    }
}
</script>
<style lang="less" type="text/less">
@import "/public/css/pdf_default";
</style>