<template>
  <div>
    <div class="perInfo A4Container flexCloumnCenter">
      <div class="form">
        <div class="table" style="height: 100%;">



          <el-row class='' style="">
            <el-col :span="4" class="cell cell50 flexCenter" style="border-left: 0;font-weight: 700;">
              项目名称
            </el-col>
            <el-col :span="20" class="cell cell50 flexStart" style="padding: 0 3px;line-height:unset;">{{ formData.project_name }}</el-col>
          </el-row>
          <el-row class='' style="">
            <el-col :span="4" class="cell cell50 flexCenter" style="border-left: 0;font-weight: 700;">
              团队名称
            </el-col>
            <el-col :span="20" class="cell cell50" style="padding: 0 3px;">{{ formData.team_name }}</el-col>
          </el-row>
          <el-row class='' style="">
            <el-col :span="4" class="cell cell50 flexCenter" style="border-left: 0;font-weight: 700;">
              项目类别
            </el-col>
            <el-col :span="20" class="cell cell50" style="padding: 0 3px;">{{ formData.type_name }}</el-col>
          </el-row>
          <el-row class='' style="">
            <el-col :span="4" class="cell cell50 flexCenter" style="border-left: 0;font-weight: 700;">
              参赛组别
            </el-col>
            <el-col :span="20" class="cell cell50" style="padding: 0 3px;">{{ formData.group_name }}</el-col>
          </el-row>


          <el-row class="flexStretch">
            <el-col :span="4">
              <div style="width: 100%;">
                <el-row style="height:100%;">
                  <el-col :span="24" class="cell flexCenter" style="border-left: 0;height:100%;font-weight: 700;">负责人
                  </el-col>
                </el-row>

              </div>
            </el-col>
            <el-col :span="20" class='flexCloumn'>
              <el-row class='flexStretch'>
                <el-col :span="24">
                  <div style="width: 100%;">
                    <el-row>
                      <el-col :span="4" class="cell cell50 flexCenter" style="font-weight: 700;"> 姓名</el-col>
                      <el-col :span="4" class="cell cell50 flexCenter" style="font-weight: 700;">所在系部</el-col>
                      <el-col :span="4" class="cell cell50 flexCenter" style="font-weight: 700;">毕业时间 </el-col>
                      <el-col :span="4" class="cell cell50 flexCenter" style="font-weight: 700;">学历/学位</el-col>
                      <el-col :span="4" class="cell cell50 flexCenter" style="font-weight: 700;">所学专业</el-col>
                      <el-col :span="4" class="cell cell50 flexCenter" style="font-weight: 700;">手机号码</el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="4" class="cell cell50 flexCenter" style='line-height: unset;'>{{ principal_info.name || '-' }}</el-col>
                      <el-col :span="4" class="cell cell50 flexCenter" style='line-height: unset;'>{{ principal_info.department_name || '-'
                      }}</el-col>
                      <el-col :span="4" class="cell cell50 flexCenter" style='line-height: unset;'>{{ principal_info.graduate_time || '-' }}</el-col>
                      <el-col :span="4" class="cell cell50 flexCenter" style='line-height: unset;'>{{ principal_info.degree || '-' }}</el-col>
                      <el-col :span="4" class="cell cell50 flexCenter" style='line-height: unset;'>{{ principal_info.major
                        || '-' }}
                      </el-col>
                      <el-col :span="4" class="cell cell50 flexCenter" style='line-height: unset;'>{{ principal_info.phone || '-' }}</el-col>

                    </el-row>

                  </div>
                </el-col>

              </el-row>




            </el-col>
          </el-row>



          <el-row class="flexStretch">
            <el-col :span="4">
              <div style="width: 100%;">
                <el-row style="height:100%;">
                  <el-col :span="24" class="cell flexCenter" style="border-left: 0;height:100%;font-weight: 700;">团队主要成员</el-col>
                </el-row>

              </div>
            </el-col>
            <el-col :span="20" class='flexCloumn'>
              <el-row class='flexStretch'>
                <el-col :span="24">
                  <div style="width: 100%;">
                    <el-row>
                      <el-col :span="4" class="cell cell50 flexCenter" style="font-weight: 700;"> 姓名</el-col>
                      <el-col :span="4" class="cell cell50 flexCenter" style="font-weight: 700;">所在系部</el-col>
                      <el-col :span="4" class="cell cell50 flexCenter" style="font-weight: 700;">毕业时间 </el-col>
                      <el-col :span="4" class="cell cell50 flexCenter" style="font-weight: 700;">学历/学位</el-col>
                      <el-col :span="4" class="cell cell50 flexCenter" style="font-weight: 700;">所学专业</el-col>
                      <el-col :span="4" class="cell cell50 flexCenter" style="font-weight: 700;">手机号码</el-col>
                    </el-row>
                    <el-row v-for="(item, index) in memberList">
                      <el-col :span="4" class="cell cell50 flexCenter" style='line-height: unset;'>{{ item.name || '-' }}</el-col>
                      <el-col :span="4" class="cell cell50 flexCenter" style='line-height: unset;'>{{ item.department_name || '-'
                      }}</el-col>
                      <el-col :span="4" class="cell cell50 flexCenter" style='line-height: unset;'>{{ item.graduate_time || '-' }}</el-col>
                      <el-col :span="4" class="cell cell50 flexCenter" style='line-height: unset;'>{{ item.degree || '-' }}</el-col>
                      <el-col :span="4" class="cell cell50 flexCenter" style='line-height: unset;'>{{ item.major
                        || '-' }}
                      </el-col>
                      <el-col :span="4" class="cell cell50 flexCenter" style='line-height: unset;'>{{ item.phone || '-' }}</el-col>
                    </el-row>
                  </div>
                </el-col>
              </el-row>


            </el-col>
          </el-row>




          <el-row class="flexStretch borderB">
            <el-col :span="4">
              <div style="width: 100%;">
                <el-row style="height:100%;">
                  <el-col :span="24" class="cell flexCenter" style="border-left: 0;height:100%;font-weight: 700;">指导教师</el-col>
                </el-row>

              </div>
            </el-col>
            <el-col :span="20" class='flexCloumn'>
              <el-row class='flexStretch'>
                <el-col :span="24">
                  <div style="width: 100%;">
                    <el-row>
                      <el-col :span="4" class="cell cell50 flexCenter" style="font-weight: 700;"> 姓名</el-col>
                      <el-col :span="4" class="cell cell50 flexCenter" style="font-weight: 700;">所在系部</el-col>
                      <el-col :span="4" class="cell cell50 flexCenter" style="font-weight: 700;">研究方向 </el-col>
                      <el-col :span="4" class="cell cell50 flexCenter" style="font-weight: 700;">职务/职称</el-col>
                      <el-col :span="4" class="cell cell50 flexCenter" style="font-weight: 700;">手机号码</el-col>
                      <el-col :span="4" class="cell cell50 flexCenter" style="font-weight: 700;">电子邮箱</el-col>
                    </el-row>
                    <el-row v-for="(item, index) in teacherList">
                      <el-col :span="4" class="cell cell50 flexCenter" style='line-height: unset;'>{{ item.name || '-' }}</el-col>
                      <el-col :span="4" class="cell cell50 flexCenter" style='line-height: unset;'>{{ item.department_name || '-'
                      }}</el-col>
                      <el-col :span="4" class="cell cell50 flexCenter" style='line-height: unset;'>{{ item.research_area || '-' }}</el-col>
                      <el-col :span="4" class="cell cell50 flexCenter" style='line-height: unset;'>{{ item.job || '-' }}</el-col>
                      <el-col :span="4" class="cell cell50 flexCenter" style='line-height: unset;'>{{ item.phone
                        || '-' }}
                      </el-col>
                      <el-col :span="4" class="cell cell50 flexCenter" style='line-height: unset;'>{{ item.email || '-' }}</el-col>
                    </el-row>
                  </div>
                </el-col>
              </el-row>


            </el-col>
          </el-row>















        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {

  props: {
    stuinfo: {
      type: Object,
      default: {}
    },
    principal_info: {
      type: Object,
      default: {}
    },
    memberList: {
      type: Array,
      default: []
    },
    teacherList: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      isShowModal: true,
      formData: {}

    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      let _this = this
      let info = JSON.parse(JSON.stringify(_this.stuinfo))
      if (info.photo) {
        info.photo = info.photo.split("?")[0] + "?x-oss-process=image/resize,w_200"
      }
      _this.formData = info
      if (info.stu_name) {
        _this.$nextTick(() => {
          _this.resizeA()
        })
      } else {
        setTimeout(function () {
          _this.init()
        }, 400)
      }
    },
    resizeA() {
      let divHeight = 50
      let textHeight = document.getElementById(`setHometownSize`).offsetHeight
      console.log('a', divHeight, textHeight)
      let i = 1
      while (textHeight > divHeight && i < 6) {
        document.getElementById(`setHometownSize`).style.fontSize = `${14 - i}px`
        textHeight = document.getElementById(`setHometownSize`).offsetHeight
        i++
      }
    }
  }
}
</script>

<style lang="less" type="text/less">
.perInfo .el-col {
  display: flex;
  padding: 0;
  margin: 0;
}

.perInfo .el-row {
  width: 100%;
  padding: 0;
  margin: 0;
}
</style>
<style scoped="scoped" type="text/less" lang="less">
.flexStretch {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
}

.perInfo {
  font-size: 20px;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 25px 0;

  .title {
    font-size: 20px;
    line-height: 50px;
  }

  .form {
    width: calc(100% - 50px);
    height: calc(100% - 0px);
    border: 1px solid #000;
    border-top: 0;

    div {
      font-size: 14px;
      color: #323232;
      box-sizing: border-box;
    }

    .content {
      width: 100%;
      height: 100%;
      padding: 10px;
      white-space: break-spaces;
      word-break: break-all;
      overflow: hidden;
    }

    .el-row {
      //border-top: 1px solid #000;
      //border-bottom: 1px solid #000;
    }
  }

  .col-row {
    width: 100%;
    margin: 0;
    padding: 0;
  }
}


.perInfo {

  .table {
    width: 100%;
  }

  .borderR {
    border-right: 1px solid #333;
  }

  .borderB {
    border-bottom: 1px solid #333;
  }

  .FS18 {
    font-size: 18px;
  }

  .bold {
    font-weight: 700;
    font-family: Avenir, Helvetica, Arial, sans-serif;
  }

  .cell {
    border-left: 1px solid #333;
    border-top: 1px solid #333;

    min-height: 30px;
    //line-height: 30px;
    //text-align: center;
    white-space: nowrap;
    font-size: 14px;
    font-family: "宋体";
    word-break: break-all;
    white-space: normal;
  }

  .cell50 {
    min-height: 50px;
    line-height: 50px;
    font-size: 14px;
    font-family: "宋体";
  }

  .cellMore {
    border-left: 1px solid #333;
    border-top: 1px solid #333;
    min-height: 50px;
    text-align: left;
    font-size: 14px;
    font-family: "宋体";
  }
}

.uline {
  text-decoration: underline;
}
</style>
